import React from 'react'

import { PageHeader } from '../../common'

import * as styles from './tasks.module.scss'

const Tasks = () => {
   return (
      <>
         <PageHeader title="Tasks" subtitle="Open Tasks" />
      </>
   )
}

export default Tasks
