import React from 'react'

import { LayoutLoggedIn } from '../components/views/layout'
import Tasks from '../components/views/tasks/tasks'

export default function TasksPage() {
   return (
      <LayoutLoggedIn>
         <Tasks />
      </LayoutLoggedIn>
   )
}
